import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getEquipmentsByUser } from '~/utils/serverClient';
import type { Equipments } from '~/shared/types';

export const useEquipments = defineStore('equipments', () => {
  const collect = ref<boolean>(false);
  const equipments = ref<Equipments>();

  const getEquipments = async (token: string, userId: string): Promise<Equipments | undefined> => {
    if (!collect.value) {
      equipments.value = await getEquipmentsByUser(token, userId);
      collect.value = true;
    }
    return equipments.value;
  };

  const reset = () => {
    collect.value = false;
    equipments.value = undefined;
  };

  return {
    collect,
    equipments,
    getEquipments,
    reset,
  };
});
